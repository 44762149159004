/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * This file contains only the print styles from the HTML5 Boilerplate
 * project. See <https://github.com/h5bp/html5-boilerplate> for more
 * information on the main project.
 */

/* ==========================================================================
   Print styles.
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    border-color: #000 !important;
    /* Black prints faster:
     http://www.sanbeiji.com/archives/953 */
     box-shadow: none !important;
     text-shadow: none !important;
   }

   a,
   a:visited {
    text-decoration: underline;
  }

  // a[href]:after {
  //   content: " (" attr(href) ")";
  // }

  // abbr[title]:after {
  //   content: " (" attr(title) ")";
  // }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

   a[href^="#"]:after,
   a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

   thead {
    // display: table-header-group;
  }

  tr,
  img {
    // page-break-inside: avoid;
  }

  img {
    max-width: none !important;
    width: auto !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .not-front .section-content {
    border-top: none !important;
  }

  .section-sticky-header,
  .skip-link,
  .slicknav_menu,
  #tabs,
  #admin-menu,
  .section-header,
  .section-breadcrumb,
  #footer {
    display: none;
  }

  .node-product-page--default {
    .field-name-field-product,
    .field-name-field-expendable,
    .field-name-field-gallery-images,
    .group-product-extra {
      display: none;
    }
    .group-product-description {
      width: 100%;
      float: none;
      clear: both;
    }
  }

}
